.custom_select_box {
	display: grid;
	label {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #1b3751;
		margin-bottom: 5px;
	}

	> div {
		position: relative;
		.select_box {
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 8px;
			background: #ffffff;
			border: 1px solid #ebedf0;
			border-radius: 6px;
			.selected_box_multi {
				display: flex;
				align-items: center;
				gap: 10px;
			}
			span {
				font-weight: 400;
				font-size: 12px;
				line-height: 24px;
				white-space: nowrap;

				svg {
					margin-left: 5px;
				}
			}
			.select_item {
				color: #afb0b6;
			}
			.selected {
				color: #5386e4;
			}

			svg {
				path {
					fill: #2f569b;
				}
			}
		}

		.select_drop_box {
			display: none;
			width: 100%;
			z-index: 5;

			ul {
				background: #ffffff;
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
				border-radius: 8px;
				padding: 14px;
				width: calc(100% - 28px);

				li {
					padding: 8px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-weight: 400;
					font-size: 12px;
					line-height: 18px;
					color: rgba(6, 17, 10, 0.5);
					justify-content: space-between;

					button {
						font-weight: 400;
						font-size: 12px;
						line-height: 18px;
						color: #1b3751;
					}

					.checkmark {
						&::after {
							top: -23% !important;
						}
					}

					&:hover {
						background: #eff6ff;
						border-radius: 4px;
						color: #06110a;

						button {
							color: #06110a;
						}
					}
				}
			}
		}
	}

	> div:hover .select_drop_box {
		display: block;
		position: absolute;
		top: 100%;
		padding-top: 16px;
	}
}
