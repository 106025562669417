.breadcrumb {
	display: flex;
	align-items: center;
    gap: 5px;
	li {
        display: flex;
        align-items: center;
		a {
			font-weight: 400;
			font-size: 14px;
			line-height: 22px;
			color: #95969d;
		}
        &::after{
            content: ">";
            color: #95969d;
        }

        &:last-child{
            &::after{
                content: "";
            }
        }
	}
    li.active{
        a{
            color: #1b3751 !important;
        }
    }
}
