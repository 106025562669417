.primary {
	background: #356899;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	color: #ffffff;

	&:hover {
		box-shadow: 0px 20px 35px rgba(53, 104, 153, 0.2);
	}
}
.primary_border {
	border: 1px solid #42526e;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	&:hover {
		box-shadow: 0px 20px 35px rgba(53, 104, 153, 0.2);
	}
}

.gray {
	font-weight: 500;
	border-radius: 32px;
	font-size: 14px;
	line-height: 24px;
	background-color: #cacbce;
	color: #1b3751;
	&:hover {
		box-shadow: 0px 20px 35px rgba(53, 104, 153, 0.2);
	}
}

.gray_border {
	border: 1px solid #95969d;
	border-radius: 32px;
	color: #95969d;
	&:hover {
		box-shadow: 0px 20px 35px rgba(53, 104, 153, 0.2);
	}
}
.sky_blue {
	background: #edf3fc;
	color: #95969d;
	&:hover {
		background: #356899;
		color: #fff;

		svg {
			path {
				fill: #fff;
			}
		}
	}
}
a,
button {
	border-radius: 8px;
	display: flex;
	align-items: center;
	transition: all 0.3s;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
}
