header {
  padding: 8px 10px 0;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 10px 10px 0px 0px;

  .logo__btns {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img.mashghool_logo {
      width: 55px;
      height: 36px;
    }

    .icons__user {
      display: flex;
      align-items: center;

      .search_box {
        margin-left: 20px;
        position: relative;

        &::after {
          position: absolute;
          border-left: 1px solid #e6e6e6;
          left: -10px;
          top: 0;
          content: '';
          height: 100%;
          display: inline-block;
        }

        span {
          position: absolute;
          left: 8px;
          top: 55%;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);

          svg {
            path {
              transition: all 0.3s;
              -webkit-transition: all 0.3s;
              -moz-transition: all 0.3s;
              -ms-transition: all 0.3s;
              -o-transition: all 0.3s;
            }
          }

          &:hover svg {
            path {
              stroke: #1b3751;
            }
          }
        }

        input {
          width: 245px;
          height: 35px;
          border-radius: 10px;
          background-color: transparent;
        }

        input.disable {
          width: calc(35px - 16px) !important;
        }

        &:hover svg {
          path {
            stroke: #1b3751;
          }
        }
      }

      //button {
      //	border: 1px solid #f3f3f3;
      //	border-radius: 10px;
      //	width: 36px;
      //	height: 36px;
      //	display: flex;
      //	align-items: center;
      //	justify-content: center;
      //}
      .notification {
        margin-left: 20px;
        position: relative;

        &::after {
          position: absolute;
          border-left: 1px solid #e6e6e6;
          left: -10px;
          top: 0;
          content: '';
          height: 100%;
          display: inline-block;
        }
      }

      .notification_active {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          background: #d71a21;
          width: 5px;
          display: inline-block;
          z-index: 1;
          height: 5px;
          top: 7px;
          right: 6px;
          border-radius: 100%;
          -webkit-border-radius: 100%;
          -moz-border-radius: 100%;
          -ms-border-radius: 100%;
          -o-border-radius: 100%;
          border: 1px solid #fff;
        }
      }

      .drop_down {
        .img_name__arrow {
          display: flex;
          align-items: center;

          .img__name {
            display: flex;
            align-items: center;
            margin-left: 20px;

            img {
              width: 28px;
              height: 28px;
              margin-left: 10px;
            }

            span {
              font-weight: 400;
              font-size: 12px;
              line-height: 12px;
              color: #000000;
            }
          }
        }
      }
    }
  }

  nav {
    margin-top: 5px;
    margin-right: 70px;

    ul {
      display: flex;
      align-items: center;
      gap: 15px;

      li {
        position: relative;
        padding: 0 10px;
        padding-bottom: 4px;

        a {
          font-weight: 700;
          font-size: 16px;
          text-align: center;
          color: #000000;
          opacity: 0.3;
        }
      }

      .active {
        a {
          opacity: 1;
          color: #1b3751;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: -1px;
          right: 0;
          width: 100%;
          height: 2px;
          background: #1b3751;
          border-radius: 2px;
        }
      }
    }
  }
}
