input,
textarea {
	background: #ffffff;
	border: 1px solid #ebedf0;
	border-radius: 6px;
	height: 40px;
	padding: 0 8px;

	&::placeholder {
		font-weight: 400;
		font-size: 12px;
		line-height: 24px;
		color: #afb0b6;
	}
}

textarea {
	padding: 8px;
}

.custom_input_box {
	display: grid;
	label {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #1b3751;
		margin-bottom: 5px;
	}
}

.custom_radio_box {
	display: inline-flex;
	cursor: pointer;

	.text {
		font-weight: 400;
		font-size: 12px;
		line-height: 19px;
		color: #1b3751;
		margin-left: 5px;
	}
	> div {
		position: relative;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		margin-bottom: -3px;
		user-select: none;
		height: 16px;
		width: 16px;
		background: #ffffff;

		border: 1px solid #cacbce;
		border-radius: 100%;
		-webkit-border-radius: 100%;
		-moz-border-radius: 100%;
		-ms-border-radius: 100%;
		-o-border-radius: 100%;

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;
			&:checked {
				~ {
					.checkmark {
						border: 5px solid #055e9e !important;
						width: 7px;
						height: 7px;
						&:after {
							display: block;
						}
					}
				}
			}
		}

		.checkmark {
			position: absolute;
			border-radius: 100%;
			top: 48%;
			left: 51%;
			transform: translate(-52%, -46%);
			width: 100%;
			height: 100%;
			-webkit-transform: translate(-52%, -46%);
			-moz-transform: translate(-52%, -46%);
			-ms-transform: translate(-52%, -46%);
			-o-transform: translate(-52%, -46%);
			-webkit-border-radius: 100%;
			-moz-border-radius: 100%;
			-ms-border-radius: 100%;
			-o-border-radius: 100%;
			&:after {
				content: '';
				position: absolute;
				display: none;
				top: 50%;
				left: 50%;
				transform: translate(-52%, -46%);
				width: 3px;
				height: 6px;
				-webkit-transform: translate(-52%, -46%);
				-moz-transform: translate(-52%, -46%);
				-ms-transform: translate(-52%, -46%);
				-o-transform: translate(-52%, -46%);
			}
		}
	}
}

.custom_checkbox {
	display: inline-flex;
	cursor: pointer;

	.text {
		font-weight: 400;
		font-size: 12px;
		line-height: 19px;
		color: #1b3751;
		margin-right: 8px;
	}
	> div {
		position: relative;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		margin-bottom: -3px;
		user-select: none;
		height: 19px;
		width: 19px;
		background: #ffffff;

		border: 1px solid #cacbce;
		border-radius: 100%;
		-webkit-border-radius: 100%;
		-moz-border-radius: 100%;
		-ms-border-radius: 100%;
		-o-border-radius: 100%;

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;
			&:checked {
				~ {
					.checkmark {
						background: #055e9e;
						width: 20px;
						height: 20px;
						&:after {
							display: block;
							top: 7% !important;
							left: 63% !important;
							content: url(../../assets/icons/checkIcon.svg);
						}
					}
				}
			}
		}

		.checkmark {
			position: absolute;
			border-radius: 100%;
			top: 48%;
			left: 51%;
			transform: translate(-50%, -50%);
			width: 100%;
			height: 100%;
			-webkit-transform: translate(-50%, -50%);
			-moz-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			-o-transform: translate(-50%, -50%);
			-webkit-border-radius: 100%;
			-moz-border-radius: 100%;
			-ms-border-radius: 100%;
			-o-border-radius: 100%;
			&:after {
				content: '';
				position: absolute;
				display: none;
				top: 50%;
				left: 50%;
				transform: translate(-52%, -46%);
				width: 3px;
				height: 6px;
				-webkit-transform: translate(-52%, -46%);
				-moz-transform: translate(-52%, -46%);
				-ms-transform: translate(-52%, -46%);
				-o-transform: translate(-52%, -46%);
			}
		}
	}
}

.custom_checkbox.disabled {
	cursor: not-allowed !important;
	> div {
		background: #f2f2f3 !important;
		border: none;
	}

	.text {
		color: #95969d !important;
	}
}

.search_custom_input {
	display: grid;
	position: relative;
	ul {
		position: absolute;
		width: calc(100% - 28px);
		top: 100%;
		margin-top: 16px;
		background: #ffffff;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		padding: 14px;
		z-index: 1;

		li {
			button {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 8px 33px 8px 10px;
				border-radius: 4px;
				width: 100%;
				&:hover {
					background: #eff6ff;
				}

				span {
					font-weight: 400;
					font-size: 12px;
					line-height: 18px;
					color: #767b77;
					span {
						color: #1b3751;
					}
				}
			}
		}
	}
}
