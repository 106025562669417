.custom_check_button {
	display: flex;
	align-items: center;

	label {
		font-weight: 500;
		font-size: 12px;
		line-height: 19px;
		color: #1b3751;
		margin-left: 10px;
	}

	button {
		width: 44px;
		height: 26px;
		background: #95969d;
		border-radius: 16px;
		position: relative;
		transition: all 0.3s;
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-ms-transition: all 0.3s;
		-o-transition: all 0.3s;
		i {
			position: absolute;
			width: 18px;
			height: 18px;
			background: #ffffff;
			box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
			top: 50%;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			-o-transform: translateY(-50%);
			border-radius: 100%;
			left: 4px;
			-webkit-border-radius: 100%;
			-moz-border-radius: 100%;
			-ms-border-radius: 100%;
			-o-border-radius: 100%;
			transition: all 0.3s;
			-webkit-transition: all 0.3s;
			-moz-transition: all 0.3s;
			-ms-transition: all 0.3s;
			-o-transition: all 0.3s;
		}
	}

	button.active {
		background: #356899 !important;
		i {
			background-color: #fff !important ;
			left: 22px !important;
		}
	}
}
