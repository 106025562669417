@tailwind base;
@tailwind components;
@tailwind utilities;
// reset
@import './base/resetStyle';
// reset
@import './base/fonts';
// mixin
@import './base/mixins';
// base
@import './base/global';
// component
@import './components/button';
@import './components/input';
@import './components/selectBox';
@import './components/checkButton';
@import './components/breadcrumb';
.wow {
    @apply bg-danger;
}