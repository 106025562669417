/* #region global */

html {
	// scroll-behavior: smooth;
}

body {
	direction: rtl;
	text-align: right;
	background-color: #42526e;
}
* {
	font-family: yekanBakh !important;
}

.important_item {
	&::before {
		content: '*';
		font-weight: 700;
		font-size: 15px;
		line-height: 23px;
		color: #f74455;
		margin-left: 4px;
	}
}

button {
	&:disabled {
		opacity: 0.6 !important;
		cursor: not-allowed !important;
	}
}

input,
textarea {
	outline: none;
	transition: all 0.3s;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	&:focus {
		background: #ffffff !important;
		box-shadow: 0px 0px 8px rgba(145, 145, 145, 0.15);
	}
}
