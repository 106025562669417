@import './../../../style/base/mixins';

.container {
	max-width: 1400px;
	margin: 0 auto;

	@include mq(1400) {
		max-width: 1200px;
	}
	@include mq(1200) {
		max-width: 992px;
	}
	@include mq(992) {
		max-width: 768px;
	}
	@include mq(768) {
		max-width: 576px;
	}
	@include mq(576) {
		max-width: 360px;
	}
}
