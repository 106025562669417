.layout {
	padding: 14px 0 12px;

	.aside__main_content {
		display: grid;
		grid-template-columns: repeat(12, minmax(0, 1fr));

		aside {
			grid-column-start: span 2;
		}

		.main_content__header {
			grid-column-start: span 10;
			background-color: #fafafd;
			border-radius: 10px 10px 0px 0px;
		}
	}
}
